<template>
  <div class="navbar-container">
    <nav
      class="navbar flex justify-between items-center h-full w-full px-md mx-auto gap-md"
    >
      <slot />
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.navbar-container {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  grid-row: 3;
  width: 100vw;
  height: $main-bottom-navigation-height;
  background-color: color('white');
  box-shadow: $shadow-lg;

  .navbar {
    max-width: $main-content-width;
  }
}
</style>
